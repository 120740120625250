import logo from './ricci.svg'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import appleBadge from './appleBadge.svg'
import googleBadge from './googleBadge.svg'
import huaweiBadge from './huaweiBadge.svg'
import popupScreenshot from './screenshots/popup-Apple iPhone X Black.png'
import dictionaryScreenshot from './screenshots/dictionary-Apple iPhone X Black.png'
import translateParagraphScreenshot from './screenshots/translate_text-Apple iPhone X Black.png'
import translateArticleScreenshot from './screenshots/translate_article-Apple iPhone X Black.png'
import newsfeedScreenshot from './screenshots/newsfeed-Apple iPhone X Black.png'
import sectionsScreenshot from './screenshots/sections-Apple iPhone X Black.png'

// Ricci's signature green color.
const PRIMARY_COLOR = 'rgb(63, 149, 69)'

// Info section for a specific feature.
// title: Title of the feature.
// screenshot: Imported image object of the screenshot.
// isMobile: Whether or not the UI should be styled for mobile phones.
// isFlipped: Whether or not the screenshot and the text description should be flipped.
// children: The text description component.
function FeatureInfo({ title, screenshot, isMobile, isFlipped, children }) {
  const width = isMobile ? '100%' : '50%'

  // Style for inner container. We have an inner container so that we can set a maxWidth
  // while keeping things centered.
  let containerFlexDirection = 'column'
  if (!isMobile && !isFlipped) {
    containerFlexDirection = 'row'
  } else if (!isMobile && isFlipped) {
    containerFlexDirection = 'row-reverse'
  }  
  const innerContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: containerFlexDirection,
    width: '100%',
    maxWidth: 800,
  }

  const featureTextStyle = Object.assign({}, styles.featureText, {
    width: width,
  })

  // We have an inner feature style so we can align the text differently
  // for mobile phones, while still keeping things centered.
  const featureTextInnerStyle = {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    textAlign: isMobile ? 'center' : 'left',
  }

  const screenshotContainerStyle = {
    width: width,
    display: 'flex',
    justifyContent: 'center',
  }

  return (
    <div style={styles.featureInfoContainer}>
      <div style={innerContainerStyle}>
        <div style={featureTextStyle}>
          <div style={featureTextInnerStyle}>
            <h2 style={styles.featureTitle}>{title}</h2>
            {children}
          </div>
        </div>
        <div style={screenshotContainerStyle}>
          <img src={screenshot} alt="screenshot" style={styles.screenshot} />
        </div>
      </div>
    </div>
  )
}

// Landing page component.
export default function Landing() {
  const isMobile = useMediaQuery('only screen and (max-width: 768px)')
  const headerStyle = Object.assign({}, styles.header, {
    fontSize: (isMobile ? 36 : 54),
  })
  const currentYear = new Date().getFullYear()

  return (
    <div>
      <div style={styles.logoContainer}>
        <img src={logo} alt="logo" style={styles.logo} />
      </div>
      <div style={styles.content}>
        <h1 style={headerStyle}>Learn Chinese by Reading News</h1>
        <div style={styles.description}>
          Read an article a day. It works.
        </div>
        <div style={styles.badgeContainer}>
          <a href="https://apps.apple.com/us/app/ricci-learn-chinese-read-news/id1554124977">
            <img src={appleBadge} alt="Download on the Apple App Store" style={styles.badge} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=sg.ricci.android&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img src={googleBadge} alt="Get it on Google Play" style={styles.badge} />
          </a>
          <a href="https://appgallery.huawei.com/#/app/C104231171">
            <img src={huaweiBadge} alt="Explore it on the Huawei App Gallery" style={styles.badge} />
          </a>
        </div>
      </div>
      <FeatureInfo
        title="News Feed"
        screenshot={newsfeedScreenshot}
        isMobile={isMobile}
        isFlipped={true}
      >
        <p style={styles.newsfeedDescription}>Reading news daily is a great way to learn Chinese. Here are three reasons.</p>
        <ol>
          <li style={styles.newsfeedDescription}>1. You can integrate it into your daily routine.</li>
          <li style={styles.newsfeedDescription}>2. You learn how Chinese is used in society.</li>
          <li style={styles.newsfeedDescription}>3. You have access to information that is only available in Chinese.</li>
        </ol>
      </FeatureInfo>
      <hr style={styles.featureDivider} />
      <FeatureInfo
        title="Get Word Definitions on Tap"
        screenshot={popupScreenshot}
        isMobile={isMobile}
        isFlipped={false}
      >
        <p>Tap on a Chinese word to pull up its dictionary entry.</p>
      </FeatureInfo>
      <hr style={styles.featureDivider} />
      <FeatureInfo
        title="Translate Text"
        screenshot={translateParagraphScreenshot}
        isMobile={isMobile}
        isFlipped={true}
      >
        <p>Translate a paragraph of text if you don't understand it.</p>
      </FeatureInfo>
      <hr style={styles.featureDivider} />
      <FeatureInfo
        title="Translate an Article"
        screenshot={translateArticleScreenshot}
        isMobile={isMobile}
        isFlipped={false}
      >
        <p>Translate a whole article with a single click if you are short on time.</p>
      </FeatureInfo>
      <hr style={styles.featureDivider} />
      <FeatureInfo
        title="Look up Words in the Dictionary"
        screenshot={dictionaryScreenshot}
        isMobile={isMobile}
        isFlipped={true}
      >
        <p>Search for word definitions directly in the dictionary.</p>
      </FeatureInfo>
      <hr style={styles.featureDivider} />
      <FeatureInfo
        title="News Sections"
        screenshot={sectionsScreenshot}
        isMobile={isMobile}
        isFlipped={false}
      >
        <p>News articles are grouped into different sections for your convenience.</p>
      </FeatureInfo>
      <div style={styles.footer}>
        <div style={styles.copyright}>Copyright © {currentYear} Ricci Pte. Ltd.</div>
      </div>
    </div>
  )
}

const styles = {
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 5,
  },
  logo: {
    height: 44,
  },
  content: {
    backgroundColor: PRIMARY_COLOR,
    padding: 20,
  },
  header: {
    color: 'white',
    fontWeight: 'bold',
    margin: '0 auto',
    maxWidth: '80%',
    padding: 10,
    textAlign: 'center',
    textShadow: 'rgb(0 0 0 / 50%) 2px 2px 6px',
  },
  description: {
    color: 'rgb(240,255,240)',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.3,
    padding: 10,
    textAlign: 'center',
  },
  badgeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  badge: {
    padding: 10,
    height: 40,
    width: 135,
    resizeMode: 'contain',
  },
  featureDivider: {
    margin: 0,
    border: '1px solid #F5F5F5',
  },
  featureInfoContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  featureText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  featureTitle: {
    fontWeight: 'bold',
    fontSize: 30,
    marginBottom: 10,
  },
  screenshot: {
    padding: 20,
    height: 500,
    objectFit: 'contain',
  },
  newsfeedDescription: {
    padding: 5,
  },
  footer: {
    backgroundColor: '#F5F5F5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  copyright: {
    color: '#999',
    fontSize: 13,
  },
}